.container {
  width: 433px;
  height: 137px;
  background:var(--background);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  border: 1px solid #01a6f0;
}

.title {
  width: 50px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: var(--color);
}

.amount {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 44px;
  background: var(--text-bg);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

@media screen and (max-width: 920px) {
  .container {
    width: 272px;
    height: 97px;
  }

  .title {
    font-size: 16px;
  }

  .amount {
    font-size: 24px;
  }
}

@media screen and (max-width: 560px) {
  .container {
    width: 240px;
  }
}

@media screen and (max-width: 526px) {
  .container {
    width: 300px;
    height: 76px;
  }

  .title {
    font-size: 14px;
  }
}
