.container {
  width: 583px;
  margin: 67px auto 0 auto;
}

.title {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  color: var(--font-color);
}

.alert {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #e42c3b;
}

.message {
  width: 583px;
  height: 136px;
  margin-top: 20px;
  border: solid 1px transparent;
  background-image: linear-gradient(#01a6f0, #0047ff),
    linear-gradient(101deg, #01a6f0, #0047ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
  -webkit-appearance: none;
  border-radius: 4px;
  outline: none;
  padding: 20px 0 0 28px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #1b1a19;
}

.message::placeholder {
  color: #4d4d4d;
}

textarea {
  resize: none !important;
}

.username {
  width: 583px;
  height: 61px;
  margin-top: 20px;
  padding: 0px 0 0 28px;
  border: solid 1px transparent;
  background-image: linear-gradient(#01a6f0, #0047ff),
    linear-gradient(101deg, #01a6f0, #0047ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
  -webkit-appearance: none;
  border-radius: 4px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #1b1a19;
  outline: none;
}

.username::placeholder {
  color: #4d4d4d;
}

.button {
  width: 282px;
  height: 56px;
  margin-top: 48px;
  margin-bottom: 88px;
  background: #0047ff;
  border-radius: 4px;
  border: none;
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.button:hover {
  box-shadow: 0px 0px 12px #60c2ff;
}

.shimmer {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin: 0 auto;
  display: inline;
  margin-bottom: 0;
}
.shimmer {
  text-align: center;
  color: rgba(255, 255, 255, 0.1);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  background: -moz-gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  background: gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  -webkit-background-size: 25px 100%;
  -moz-background-size: 75px 100%;
  background-size: 75px 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-animation-name: shimmer;
  -moz-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: #222;
}
@-moz-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-webkit-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-o-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

@media screen and (max-width: 920px) {
  .container {
    width: 508px;
  }
  .message {
    width: 508px;
  }

  .username {
    width: 508px;
  }
}

@media screen and (max-width: 526px) {
  .container {
    width: 300px;
  }

  .title {
    font-size: 24px;
  }

  .message {
    width: 300px;
  }

  .username {
    width: 300px;
  }

  .shimmer {
    font-size: 16px;
  }
}
