.container {
  width: 583px;
  position: relative;
  height: auto;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid;
  border-image: linear-gradient(
      270deg,
      rgba(21, 165, 223, 0.19) 0%,
      #15a5df 56.25%,
      rgba(21, 165, 223, 0.19) 100%
    )
    10;
}

.comments {
  display: flex;
  flex-direction: row;
  height: 26px;
  align-items: center;
  position: absolute;
  right: 15px;
  bottom: 10px;
}

.count {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #01a6f0;
  margin-left: 9px;
}

.logo img {
  width: 57px;
  height: 57px;
  border-radius: 50%;
}

.messageInfo {
  width: 503px;
  margin-left: 22px;
  text-align: left;
  word-break: break-all;
}

.user {
  display: flex;
  flex-direction: row;
}

.username {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: var(--font-color);
  margin-top: 3px;
}

.address {
  margin-left: 10px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: var(--font-color2);
}

.message {
  margin-top: 6px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 139.9%;
  color: var(--font-color3);
  word-break: break-word;
  margin-bottom: 20px;
}

.shimmer {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin: auto;
  display: inline;
  margin-top: 300px;
  margin-bottom: 390px;
  height: 50px;
}
.shimmer {
  text-align: center;
  color: rgba(255, 255, 255, 0.1);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  background: -moz-gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  background: gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  -webkit-background-size: 25px 100%;
  -moz-background-size: 75px 100%;
  background-size: 75px 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-animation-name: shimmer;
  -moz-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: #222;
}
@-moz-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-webkit-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-o-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

@media screen and (max-width: 920px) {
  .container {
    width: 508px;
  }
}

@media screen and (max-width: 526px) {
  .container {
    width: 300px;
    height: auto;
    padding-top: 19px;
    padding-bottom: 19px;
  }
  .messageInfo {
    width: 242px;
    margin-left: 16px;
  }

  .message {
    font-size: 16px;
  }

  .shimmer {
    font-size: 16px;
  }
}
