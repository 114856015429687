.container {
  width: 358px;
  height: 120px;
  background: var(--bg3);
  border: 1px solid #01a6f0;
  border-radius: 4px;
  padding-top: 14px;
  padding-bottom: 22px;
  margin-top: 125px;
  margin-left: auto;
  margin-right: auto;
}

.containerConnected {
  width: 432px;
  height: 91px;
  background: linear-gradient(
    180.3deg,
    rgba(215, 226, 255, 0) -6.44%,
    #e4ecff 99.74%
  );
  border: 1px solid #01a6f0;
  border-radius: 4px;
  padding-top: 14px;
  padding-bottom: 22px;
}

.textContainer {
  width: auto;
  height: 51px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 6px auto;
}

.title {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
  text-align: center;
  color: var(--color);
}

.text {
  height: 22px;
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: var(--color);
}

.logo {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  background: linear-gradient(267.27deg, #01a6f0 1.57%, #0047ff 98.69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

@media screen and (max-width: 920px) {
  .container {
    width: 508px;
    margin-top: 120px;
  }

  .textContainer {
    width: auto;
    height: 29px;
    align-items: center;
    margin: 10px auto;
  }
}

@media screen and (max-width: 526px) {
  .container {
    width: 300px;
    height: 97px;
    padding-top: 8px;
    padding-bottom: 0;
  }

  .logo {
    font-size: 20px;
  }

  .textContainer {
    width: auto;
    height: 38px;
    flex-direction: column;
    margin-top: 0;
  }

  .title {
    font-size: 15px;
  }

  .text {
    height: auto;
    font-size: 12px;
  }
}
