.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.empty {
  height: auto;
  margin: 80px auto 400px auto;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: var(--font-color);
}

@media screen and (max-width: 561px) {
  .empty {
    font-size: 18px;
  }
}
