.container {
  width: 508px;
  height: 50%;
  border-radius: 4px;
  margin: 0px auto 0 auto;
  border: solid 1px #01A6F0;
  background: var(--bg3);
}

.header {
  height: 49px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background: linear-gradient(267.27deg, #01a6f0 1.57%, #0047ff 98.69%);
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding-left: 34px;
}

.text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 19px 34px 19px 34px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: var(--color);
}

@media screen and (max-width: 920px) {
  .container {
    width: 508px;
    margin-top: 30px;
  }
}

@media screen and (max-width: 526px) {
  .container {
    width: 300px;
  }

  .text {
    font-size: 15px;
  }
}
