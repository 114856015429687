.container {
  width: 885px;
  min-height: 120px;
  height: auto;
  align-items: center;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  background: var(--background);
  border-radius: 4px;
  padding-left: 55px;
  padding-right: 55px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  border: 2px solid #01a6f0;
}

.input {
  width: 562px;
  height: 56px;
  background: #f7f7f7;
  border-radius: 4px;
  padding-left: 21px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #808080;
  outline: none;
  border: solid 1px transparent;
  background-image: linear-gradient(#01a6f0, #0047ff),
    linear-gradient(101deg, #01a6f0, #0047ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
  -webkit-appearance: none;
}

.button {
  width: 196px;
  height: 56px;
  background: #0047ff;
  border: 1px solid #0047ff;
  border-radius: 4px;
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
}

.button:hover {
  box-shadow: 0px 0px 12px #60c2ff;
}

.alert {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #e42c3b;
  position: absolute;
  top: 90px;
}

.shimmer {
  text-align: center;
  color: rgba(255, 255, 255, 0.1);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fff),
    to(#fff),
    color-stop(0.5, #222)
  );
  background: -moz-gradient(
    linear,
    left top,
    right top,
    from(#fff),
    to(#fff),
    color-stop(0.5, #222)
  );
  background: gradient(
    linear,
    left top,
    right top,
    from(#fff),
    to(#fff),
    color-stop(0.5, #222)
  );
  -webkit-background-size: 25px 100%;
  -moz-background-size: 75px 100%;
  background-size: 75px 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-animation-name: shimmer;
  -moz-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: #fff;
}
@-moz-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-webkit-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-o-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

@media screen and (max-width: 920px) {
  .container {
    width: 560px;
    padding-left: 21px;
    padding-right: 21px;
  }

  .input {
    width: 344px;
  }

  .button {
    width: 159px;
  }
}

@media screen and (max-width: 590px) {
  .shimmer {
    font-size: 16px;
  }
}

@media screen and (max-width: 561px) {
  .container {
    width: 500px;
  }

  .input {
    width: 284px;
  }

  .alert {
    position: inherit;
    top: 0;
  }
}

@media screen and (max-width: 526px) {
  .container {
    width: 300px;
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
    flex-direction: column;
  }

  .input {
    width: 256px;
    height: 40px;
    font-size: 16px;
  }

  .button {
    margin-top: 10px;
    width: 256px;
    height: 40px;
    font-size: 16px;
  }
}
