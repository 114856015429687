.title {
  font-family: "Nunito";
  font-size: 36px;
  font-weight: 600;
}

.header {
  display: flex;
}

.container {
  width: 45%;
  word-wrap: break-word;
}

.contentOne {
  width: 100%;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  margin-top: 20px;
  padding-bottom: 20px;
  word-wrap: break-word;
}

.contentOne:first-child {
  margin-top: 60px;
}

.cardTitle {
  width: 100%;
  height: 65px;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-weight: 600;
}

.titleS {
  padding-left: 20px;
  font-weight: 600;
  word-wrap: break-word;
}

.titleSm {
  padding-left: 5px;
  font-weight: 600;
  word-wrap: break-word;
}

.cardText {
  margin-left: 20px;
}

.step {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  word-break: break-word;
  flex-wrap: wrap;
}

@media screen and (max-width: 1112px) {
  .container {
    width: 100%;
  }
}
