.container {
  display: flex;
  flex-direction: column;
  height: 300px;
  border-radius: 10px;
  position: relative;
  z-index: 2;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 8%) 0px 4px 15px;
  transition: box-shadow 0.25s ease-in-out 0s;
  margin-top: 36px;
  padding-bottom: 30px;
  cursor: pointer;
  margin-left: 16px;
}

.header {
  width: 278px;
  height: 100%;
  border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
}

.header img {
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: auto;
  display: block;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: 0.5s all ease-in-out;
}

.header img:hover {
  transform: scale(1.5);
}

.logo,
.logo svg {
  width: 44px;
  height: 44px;
}

.title,
.index {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
  color: var(--color);
  margin-left: 18px;
}

.horizontal {
  display: flex;
  flex-direction: row;
  height: 44px;
  align-items: center;
}

.button {
  margin-left: 10px;
  width: 96px;
  height: 42px;
  background: #0047ff;
  border: none;
  border-radius: 4px;
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
}

.section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  margin-top: 17px;
}

.content {
  padding: 12px 28px 0 31px;
}

.line {
  width: 100%;
  margin-top: 26px;
  border-bottom: 1px solid #15a5df;
}

@media screen and (max-width: 920px) {
  .container {
    width: 560px;
  }

  .header {
    width: 100%;
  }
}

@media screen and (max-width: 561px) {
  .container {
    width: 400px;
  }

  .header {
    width: 100%;
  }
}

@media screen and (max-width: 526px) {
  .container {
    width: 300px;
  }
  .header {
    width: 100%;
  }

  .header {
    flex-direction: column;
    position: relative;
  }

  .logo,
  .logo svg {
    width: 33px;
    height: 33px;
  }

  .title,
  .index {
    font-size: 16px;
  }

  .title {
    margin-left: 7px;
  }

  .index {
    position: absolute;
    z-index: 2;
    color: #ffffff;
    top: 57px;
  }

  .button {
    position: relative;
    width: 273px;
    height: 36px;
    margin-top: 6px;
    font-size: 14px;
  }
}
