.container {
  width: 885px;
  height: auto;
  margin: 64px auto 0 auto;
  background:var(--background);
  border-radius: 4px;
  padding-top: 34px;
  padding-bottom: 39px;
  border: 1px solid #01a6f0;
}

.section {
  width: 776px;
  height: 83px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.firstSection {
  width: 776px;
  height: 83px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.select {
  width: 337px;
}

.selectToken {
  width: 776px;
  margin-top: 20px;
}

.selectToken select {
  width: 776px;
  height: 56px;
  padding-left: 15px;
  border-radius: 4px;
  outline: none;
  border: solid 1px transparent;
  background-image: linear-gradient(#01a6f0, #0047ff),
    linear-gradient(101deg, #01a6f0, #0047ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
  -webkit-appearance: none;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #08142a;
}

.select select {
  width: 337px;
  height: 56px;
  padding-left: 15px;
  border-radius: 4px;
  outline: none;
  border: solid 1px transparent;
  background-image: linear-gradient(#01a6f0, #0047ff),
    linear-gradient(101deg, #01a6f0, #0047ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
  -webkit-appearance: none;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #08142a;
}

.selectTitle {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 139.9%;
  color: #01a6f0;
  text-align: left;
  margin-left: 15px;
}

.button {
  background-color: transparent;
  border: none;
  margin-top: 22px;
}

.input input {
  width: 776px;
  height: 56px;
  padding-left: 15px;
  border-radius: 4px;
  outline: none;
  border: solid 1px transparent;
  background-image: linear-gradient(#01a6f0, #0047ff),
    linear-gradient(101deg, #01a6f0, #0047ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
  -webkit-appearance: none;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #08142a;
}

.box {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  position: absolute;
  right: 12px;
}

.option {
  display: flex;
  flex-direction: row;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #808080;
}

.alert {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 139.9%;
  color: #e42c58;
}

@media screen and (max-width: 920px) {
  .container {
    width: 561px;
    height: auto;
  }

  .firstSection {
    width: 504px;
    flex-direction: column;
    margin-top: 0;
    height: 219px;
  }

  .section {
    width: 504px;
    flex-direction: column;
    height: auto;
    margin-top: 0;
  }
  .selectToken {
    width: 504px;
  }
  .input {
    width: 504px;
    margin-top: 20px;
  }

  .select {
    width: 504px;
  }

  .input input {
    width: 504px;
    margin-top: 0;
  }

  .select select,
  .selectToken select {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .button {
    margin-top: 0px;
    transform: rotate(90deg);
  }
}

@media screen and (max-width: 570px) {
  .container {
    width: 400px;
    height: auto;
  }

  .firstSection {
    width: 370px;
    flex-direction: column;
    margin-top: 0;
    height: 219px;
  }

  .section {
    width: 370px;
    flex-direction: column;
    height: auto;
    margin-top: 0;
  }
  .selectToken {
    width: 370px;
  }
  .input {
    width: 370px;
    margin-top: 20px;
  }

  .select {
    width: 370px;
  }

  .input input {
    width: 370px;
    margin-top: 0;
  }

  .select select,
  .selectToken select {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 526px) {
  .container {
    width: 300px;
    height: auto;
  }

  .firstSection {
    width: 277px;
    flex-direction: column;
    margin-top: 0;
    height: 167px;
  }

  .section {
    width: 277px;
    flex-direction: column;
    height: auto;
    margin-top: 0;
  }
  .selectToken {
    width: 277px;
  }
  .input {
    width: 277px;
    margin-top: 20px;
  }

  .select {
    width: 277px;
  }

  .input input {
    width: 277px;
    margin-top: 0;
    height: 40px;
    font-size: 16px;
  }

  .select select,
  .selectToken select {
    width: 100%;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
  }

  .option {
    font-size: 16px;
  }

  .button svg {
    width: 33px;
    height: 33px;
  }

  .selectTitle {
    font-size: 14px;
  }
}
