.container {
  height: 234px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.containerMobile {
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 44px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.container a svg path {
  fill: var(--fill-color);
}

.containerMobile a svg path {
  fill: var(--fill-color);
}

.container a:hover svg path {
  fill: var(--fill-color);
}

.containerMobile a:hover svg path {
  fill: var(--fill-color);
}

@media screen and (max-width: 1015px) {
  .container {
    width: 270px;
    height: auto;
    flex-direction: row;
  }
}

@media screen and (max-width: 526px) {
  .container {
    width: 270px;
    height: auto;
    flex-direction: row;
  }
}
