.container {
  width: 100%;
  height: auto;
  min-height: 347px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.itemTitle {
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 64px;
  align-items: center;
  font-family: "Nunito";
  border-bottom: 1px solid var(--border-color);
  font-weight: 600;
  position: relative;
}

.props {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--border-color);
  align-items: center;
  min-height: 100px;
}

.propCard {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  width: 184px;
  height: 68px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.propCardTitle {
  font-weight: 600;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.icon svg {
  width: 24px;
  height: 24px;
}

.icon svg path {
  fill: var(--fill-color);
}

.button {
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.itemText {
  width: 100%;
  height: auto;
  min-height: 84px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-family: "Nunito";
  border-bottom: 1px solid var(--border-color);
  text-align: left;
  word-break: break-word;
}

.item:last-child > .itemTitle {
  border-bottom: none;
}

.item:last-child > .itemText {
  border-top: 1px solid var(--border-color);
}

@media screen and (max-width: 540px) {
  .props {
    flex-direction: column;
    justify-content: space-around;
    min-height: 170px;
  }

  .itemText {
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .propCard {
    width: 290px;
  }
}
