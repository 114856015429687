.container {
  width: 358px;
  height: 56px;
  border: 1px solid #01A6F0;
  border-radius: 4px;
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  background: var(--buttonBg);
  margin-left: auto;
  margin-right: auto;
  color: var(--buttonFont);
}

.container div {
  margin: auto;
}

.container:hover {
  background: #0047FF;
  border: 1px solid #0047FF;
  box-shadow: 0px 0px 12px #60C2FF;
  background-clip: text;
}

@media screen and (max-width: 526px) {
  .container {
    width: 300px;
    font-size: 16px;
  }
}
