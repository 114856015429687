.text {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 139.9%;
  color: var(--font-color3);
  margin-bottom: 5px;
  margin-top: 20px;
}

.sm {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 139.9%;
  color: var(--font-color2);
  margin-bottom: 10px;
}

.container {
  width: 646px;
  margin: 70px auto;
  text-align: left;
}

.file {
  position: relative;
  padding: 4px;
  cursor: pointer;
  border: 3px dashed rgb(204, 204, 204);
  border-radius: 10px;
  height: fit-content;
  min-height: 200px;
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.preview {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: contain;
}

.file svg {
  width: 84px;
  height: 84px;
  margin: auto;
}

.file svg path {
  fill: rgb(204, 204, 204);
}

.input {
  width: 562px;
  height: 56px;
  background: transparent;
  border-radius: 4px;
  padding-left: 12px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: var(--font-color2);
  outline: none;
  border: solid 1px transparent;
  -webkit-appearance: none;
  border-radius: 10px;
  border: 2px solid rgb(204, 204, 204);
}

.input:focus {
  border: 2px solid rgb(76, 80, 92);
}

.textarea:focus {
  border: 2px solid rgb(76, 80, 92);
}

.textarea {
  width: 562px;
  height: auto;
  background: transparent;
  border-radius: 4px;
  padding-left: 21px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #808080;
  outline: none;
  border: solid 1px transparent;
  -webkit-appearance: none;
  border-radius: 10px;
  border: 2px solid rgb(204, 204, 204);
  padding: 12px;
  min-height: 26px;
}

.button {
  width: 282px;
  height: 56px;
  margin-top: 48px;
  background: #0047ff;
  border-radius: 4px;
  border: none;
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.button:hover {
  box-shadow: 0px 0px 12px #60c2ff;
}

span {
  color: #e42c58;
}

.property {
  width: 562px;
  margin-top: 16px;
  border-bottom: 1px solid rgb(229, 232, 235);
  padding-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.horizontal {
  display: flex;
  flex-direction: row;
  height: 24px;
  align-items: flex-end;
}

.icon svg {
  width: 24px;
  height: 24px;
}

.icon svg path {
  fill: var(--font-color4);
}

.pTitle {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 139.9%;
  color: var(--font-color3);
  margin-left: 8px;
}

.pText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 139.9%;
  color: var(--font-color3);
  margin-left: 32px;
  margin-top: 5px;
}

.pButton button {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border-radius: 12px;
  justify-content: center;
  padding: 17px;
  background-color: var(--bg2);
  border: 2px solid var(--bg2);
  width: 60px;
  height: 60px;
}

.pButton button svg {
  width: 22px;
  height: 22px;
}

.pButton button:hover {
  transition: all 0.2s ease 0s;
  background-color: var(--font-color2);
  border: none;
}

.alert {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 139.9%;
  color: #e42c58;
  margin-top: 8px;
}

.sAlert {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 139.9%;
  color: #1ed287;
  margin-top: 8px;
}

.shimmer {
  text-align: center;
  color: rgba(255, 255, 255, 0.1);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fff),
    to(#fff),
    color-stop(0.5, #222)
  );
  background: -moz-gradient(
    linear,
    left top,
    right top,
    from(#fff),
    to(#fff),
    color-stop(0.5, #222)
  );
  background: gradient(
    linear,
    left top,
    right top,
    from(#fff),
    to(#fff),
    color-stop(0.5, #222)
  );
  -webkit-background-size: 25px 100%;
  -moz-background-size: 75px 100%;
  background-size: 75px 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-animation-name: shimmer;
  -moz-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: #fff;
}
@-moz-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-webkit-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-o-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

.group {
  position: relative;
  margin-top: 20px;
  height: 1.5rem;
  display: flex;
  align-items: center;
}

.group .customCheckbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
  width: 1.5rem;
  height: 1.5rem;
}

.customCheckbox ~ .span {
  display: inline-block;
  border: 2px solid rgb(204, 204, 204);
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 4px;
}

.customCheckbox:checked ~ .span:after {
  content: "\2713";
  font-size: 1rem;
  font-weight: bold;
  position: relative;
  left: 5px;
  color: var(--font-color3);
}

.label {
  margin-left: 10px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 139.9%;
  color: var(--font-color3);
}

@media screen and (max-width: 920px) {
  .container,
  .file,
  .property {
    width: 560px;
  }

  .input {
    width: 344px;
  }

  .textarea {
    width: 344px;
  }

  .button {
    width: 159px;
  }
}

@media screen and (max-width: 561px) {
  .container,
  .file,
  .property {
    width: 500px;
  }

  .input {
    width: 284px;
  }

  .textarea {
    width: 284px;
  }
  .shimmer {
    font-size: 16px;
  }
}

@media screen and (max-width: 526px) {
  .container,
  .file,
  .property {
    width: 300px;
  }

  .input {
    width: 256px;
    height: 40px;
    font-size: 16px;
  }

  .button {
    margin-top: 10px;
    width: 256px;
    height: 40px;
    font-size: 16px;
  }
}
