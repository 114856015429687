.container {
  width: 886px;
  height: 120px;
  margin: 20px auto 54px auto;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.button {
  width: 886px;
  height: 56px;
  background: linear-gradient(180deg, #0047ff 0%, #002073 100%),
    linear-gradient(270.01deg, #0047ff 0.01%, #002073 100%);
  border-radius: 4px;
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
  border: none;
}

.button:hover {
  background: linear-gradient(267.27deg, #01A6F0 1.57%, #0047FF 98.69%);
}

.shimmer {
  text-align: center;
  color: rgba(255, 255, 255, 0.1);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fff),
    to(#fff),
    color-stop(0.5, #222)
  );
  background: -moz-gradient(
    linear,
    left top,
    right top,
    from(#fff),
    to(#fff),
    color-stop(0.5, #222)
  );
  background: gradient(
    linear,
    left top,
    right top,
    from(#fff),
    to(#fff),
    color-stop(0.5, #222)
  );
  -webkit-background-size: 25px 100%;
  -moz-background-size: 75px 100%;
  background-size: 75px 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-animation-name: shimmer;
  -moz-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: #fff;
}
@-moz-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-webkit-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-o-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

@media screen and (max-width: 920px) {
  .container {
    width: 560px;
    height: 120px;
  }

  .button {
    width: 450px;
    height: 56px;
  }
}

@media screen and (max-width: 570px) {
  .container {
    width: 400px;
    height: 120px;
  }

  .button {
    width: 350px;
    height: 56px;
  }
}

@media screen and (max-width: 526px) {
  .container {
    width: 300px;
    height: 120px;
  }

  .button {
    width: 190px;
    height: 56px;
  }
}
