.container {
  width: 885px;
  min-height: 120px;
  height: auto;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  background:var(--background);
  border-radius: 4px;
  padding-left: 55px;
  padding-right: 55px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  border: 2px solid #01a6f0;
}

.input {
  width: 350px;
  height: 56px;
  background: #f7f7f7;
  border-radius: 4px;
  padding-left: 21px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #808080;
  outline: none;
  border: solid 1px transparent;
  background-image: linear-gradient(#01a6f0, #0047ff),
    linear-gradient(101deg, #01a6f0, #0047ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
  -webkit-appearance: none;
}

.select {
  width: 200px;
  height: 56px;
  background-color: #f7f7f7;
  border-radius: 4px;
  font-family: 'Nunito';
  text-align: center;
  font-style: normal;
  font-weight: 700;
}

.button {
  width: 196px;
  height: 56px;
  background: #0047ff;
  border: 1px solid #0047ff;
  border-radius: 4px;
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
}

.button:hover {
  box-shadow: 0px 0px 12px #60c2ff;
}

.alert {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #e42c3b;
  position: absolute;
  top: 90px;
}

@media screen and (max-width: 920px) {
  .container {
    width: 560px;
    padding-left: 21px;
    padding-right: 21px;
    flex-direction: column;
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .input {
    width: 344px;
  }

  .select {
    margin-top: 10px;
    width: 344px;
  }

  .button {
    width: 159px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 561px) {
  .container {
    width: 500px;
  }

  .input {
    width: 284px;
  }

  .select {
    width: 284px;
  }

  .alert {
    position: inherit;
    top: 0;
  }
}

@media screen and (max-width: 526px) {
  .container {
    width: 300px;
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
    flex-direction: column;
  }

  .input {
    width: 256px;
    height: 40px;
    font-size: 16px;
  }

  .select {
    width: 256px;
  }

  .button {
    margin-top: 10px;
    width: 256px;
    height: 40px;
    font-size: 16px;
  }
}
