.container {
  width: 744px;
  height: 248px;
  background: var(--bg3);
  border-radius: 4px;
  margin-top: 36px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #01A6F0;
}

.header {
  width: 100%;
  height: 99px;
  background: linear-gradient(267.27deg, #01A6F0 1.57%, #0047FF 98.69%);
  border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  align-items: center;
  padding: 29px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.logo,
.logo svg {
  width: 44px;
  height: 44px;
}

.title,
.index {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
  color: var(--color);
  margin-left: 18px;
}

.horizontal {
  display: flex;
  flex-direction: row;
  height: 44px;
  align-items: center;
}

.button {
  margin-left: 10px;
  width: 96px;
  height: 42px;
  background:#0047FF;
  border: none;
  border-radius: 4px;
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #FFFFFF
}

.section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: var(--color);
  margin-top: 17px;
}

.content {
  padding: 12px 28px 0 31px;
}

.line {
  width: 100%;
  margin-top: 26px;
  border-bottom: 1px solid #15a5df;
}

@media screen and (max-width: 920px) {
  .container {
    width: 560px;
  }
}

@media screen and (max-width: 561px) {
  .container {
    width: 400px;
  }
}

@media screen and (max-width: 526px) {
  .container {
    width: 300px;
  }

  .header {
    height: 105px;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 15px;
    position: relative;
  }

  .logo,
  .logo svg {
    width: 33px;
    height: 33px;
  }

  .title,
  .index {
    font-size: 16px;
  }

  .title {
    margin-left: 7px;
  }

  .index {
    position: absolute;
    z-index: 2;
    color: #ffffff;
    top: 57px;
  }

  .button {
    position: relative;
    width: 273px;
    height: 36px;
    margin-top: 6px;
    font-size: 14px;
  }
}
