.cardList {
  width: 885px;
  margin: 20px auto 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.balance {
  margin: 64px auto 0 auto;
  height: 62px;
}

.stakeList {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 170px;
}

.stakeTitle {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  background: var(--text-bg);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.empty {
  height: auto;
  margin: 80px auto 400px auto;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: var(--font-color);
}

@media screen and (max-width: 920px) {
  .cardList {
    width: 560px;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 561px) {
  .cardList {
    width: 400px;
    flex-wrap: wrap;
  }

  .empty {
    font-size: 18px;
  }
}

@media screen and (max-width: 526px) {
  .cardList {
    width: 300px;
    flex-wrap: wrap;
  }
}
