.container {
  width: 390px;
  height: auto;
  margin: 29px auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  border-bottom: 1px solid;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-image: linear-gradient(
      270deg,
      rgba(21, 165, 223, 0.19) 0%,
      #15a5df 56.25%,
      rgba(21, 165, 223, 0.19) 100%
    )
    10;
}

.containerOrdered {
  width: 390px;
  height: auto;
  margin: 59px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 11px;
  border-bottom: 1px solid;
  border-image: linear-gradient(
      270deg,
      rgba(21, 165, 223, 0.19) 0%,
      #15a5df 56.25%,
      rgba(21, 165, 223, 0.19) 100%
    )
    10;
}

.textContainer {
  margin-left: 14px;
}

.containerOrdered svg {
  width: 84px;
  height: 84px;
}

.container svg {
  width: 84px;
  height: 84px;
}

.text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #4d4d4d;
}

.strongText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: var(--color);
  margin-top: 11px;
}
@media screen and (max-width: 526px) {
  .container {
    width: 300px;
    padding-left: 0;
    justify-content: center;
  }

  .container svg {
    width: 40px;
    height: 40px;
  }

  .text {
    font-size: 12px;
  }

  .strongText {
    font-size: 18px;
  }

  .containerOrdered {
    width: 300px;
    padding-left: 0;
    justify-content: center;
  }
}
