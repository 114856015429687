.button {
  width: 191px;
  height: 38px;
  border-radius: 50px;
  border: 1px solid var(--bg-color);
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: var(--bg-color);
  background-color: transparent;
  transition: 500ms all;
}

.button:hover {
  background: var(--bg-color);
  color: #fff;
}

.button:hover svg path {
  fill: #fff;
}

.buttonMobile {
  width: 191px;
  height: 38px;
  border-radius: 50px;
  border: 1px solid var(--bg-color);
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: var(--bg-color);
  background-color: transparent;
}

.buttonMobile:hover {
  background: var(--bg-color);
  color: #fff;
}

.buttonHome {
  width: 775px;
  height: 56px;
  background: rgba(247, 247, 247, 0.57);
  border: none;
  border-radius: 4px;
  margin: auto;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #15a5df;
}

.buttonHome:hover {
  background: #31c4ff;
  box-shadow: 0px 0px 25px #31c4ff;
  color: #fff;
}

.icon {
  display: none;
}

.walletIcon {
  display: none;
}

@media screen and (max-width: 920px) {
  .buttonHome {
    width: 458px;
  }

  .button {
    width: 154px;
    height: 30px;
    font-size: 16px;
  }
}

@media screen and (max-width: 526px) {
  .button {
    min-width: 26px;
    width: auto;
    height: 23px;
    border-radius: 4px;
    align-items: center;
  }

  .address {
    display: none;
  }

  .icon {
    display: flex;
    width: 14px;
    height: 14px;
  }

  .buttonHome {
    display: none;
  }

  .walletAddress {
    display: none;
  }

  .walletIcon {
    display: flex;
  }
}

