.button {
  width: 282px;
  height: 56px;
  background: #15a5df;
  border-radius: 4px;
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  border: none;
}

.button:hover {
  box-shadow: 0px 0px 12px #15a5df;
}

@media screen and (max-width: 526px) {
  .button {
    width: 256px;
    height: 40px;
    font-size: 16px;
  }
}
