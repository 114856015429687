.container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #2d2d2d;
  border-radius: 41px;
  width: auto;
  min-width: 81px;
  height: 50px;
  margin-top: 60px;
  position: relative;
  font-family: "Comfortaa";
  color: var(--font-color);
  border: 1px solid var(--border-color);
  background-color: transparent;
  border-radius: 23px;
  height: 46px;
  font-size: 16px;
  padding-left: 22px;
  padding-right: 22px;
}

.tabs {
  height: 202px;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  z-index: 9999;
  border: 1px solid #2d2d2d;
  border-radius: 1rem;
  margin-top: 10px;
  background-color: var(--bg2);
  border-color: var(--border-color);
  margin-left: 0px;
}

.tab {
  height: 38px;
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: var(--font-color);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 920px) {
  .tabs {
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 60px;
  }
}

@media screen and (max-width: 561px) {
  .header {
    width: 400px;
  }

  .tabs {
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 113px;
  }
}
