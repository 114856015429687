.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cardContainerOrdered {
  width: 886px;
  height: 259px;
  margin: 23px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cardContainer {
  width: 886px;
  height: 259px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cardContainerConnected {
  width: 886px;
  height: 202px;
  margin: 29px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.vertical {
  height: 259px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.horizontal {
  width: 886px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  order: 1;
}

.horizontalTwo {
  width: 886px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  order: 2;
}

.buttonContainer {
  width: 885px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px auto;
  background: linear-gradient(
    90deg,
    rgba(6, 175, 226, 0.07) 0%,
    rgba(62, 175, 114, 0.07) 98.12%
  );
  border-radius: 4px;
}

.socialBar {
  position: absolute;
  height: 234px;
  right: 105px;
  margin-top: 97px;
}

.purchaseContainer {
  margin-bottom: 48px;
}

.messageList:last-child {
  border-bottom: none !important;
  margin-bottom: 34px;
}

.more {
  margin-top: 34px;
  margin-bottom: 113px;
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: var(--bg-color);
  cursor: pointer;
}

.buttonGroup {
  width: 736px;
  margin-top: 33px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cards {
  width: 915px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 1130px) {
  .socialBar {
    right: 5px;
  }
}

@media screen and (max-width: 920px) {
  .socialBar {
    position: relative;
    order: 6;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 18px;
  }

  .cardContainer {
    width: 508px;
    flex-direction: column;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .cardContainerOrdered {
    width: 508px;
    flex-direction: column;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .cards {
    width: 508px;
    flex-direction: column;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .cardContainerConnected {
    width: 508px;
    flex-direction: column;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .buttonContainer {
    width: 508px;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  .purchaseContainer {
    margin-top: 20px;
  }

  .horizontal {
    width: 508px;
    flex-direction: column;
  }

  .buttonGroup {
    width: 358px;
    flex-direction: column;
    height: 132px;
  }
}

@media screen and (max-width: 526px) {
  .vertical {
    flex-direction: row;
    height: auto;
  }

  .buttonContainer {
    display: none;
  }

  .socialBar {
    position: relative;
    order: 6;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 18px;
  }

  .purchaseContainer {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .cardContainer {
    width: 300px;
  }

  .cardContainerConnected {
    width: 300px;
  }

  .cardContainerOrdered {
    width: 300px;
  }

  .cards {
    width: 300px;
  }

  .horizontal {
    width: 300px;
  }

  .buttonGroup {
    width: 300px;
  }
}
