.container {
  width: 886px;
  height: 460px;
  padding: 20px;
  background-color: #F5F9FE;
  margin: 36px auto 36px auto;
  border: solid 1px #01A6F0;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.planCard {
  width: 250px;
  height: 200px;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
}

.planCardTitle {
  width: 100%;
  height: 36px;
  background-color: #0047FF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
  font-family: 'Comfortaa', cursive;
  font-size: 16px;
  color: #FFF;
  border-radius: 4px;
}

.planCardInfoBox {
  width: 100%;
  height: calc(100% - 36px);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  border-spacing: 0 10px;
}

.planCardInfoTable {
  border-spacing: 0 10px;
  border-collapse: separate;
}

.planCardInfoTable tr {
  border-bottom: 1px solid #CCC;
}

.planCardInfoTableTitle {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  text-align: left;
}

.planCardInfoTableValue {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-align: right;
}

@media screen and (max-width: 920px) {
  .container {
    width: 560px;
    height: 100%;
  }
}

@media screen and (max-width: 561px) {
  .container {
    width: 500px;
    height: 100%;
  }
}

@media screen and (max-width: 526px) {
  .container {
    width: 300px;
    height: 100%;
  }
}