.container {
  margin: 64px auto 0 auto;
  width: 886px;
  height: 62px;
  background: #08142A;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 51px;
  padding-right: 52px;
  border: 2px solid #01A6F0;
}

.title {
  display: flex;
  flex-direction: row;
  height: 40px;
  align-items: center;
}

.logo svg {
  width: 40px;
  height: 40px;
}

.text {
  margin-left: 12px;
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  color: #FFFFFF;
}

.amount {
  height: 40px;
  display: flex;
  flex-direction: row;
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #FFFFFF;
  align-items: center;
}

.omc {
  margin-left: 12px;
}

@media screen and (max-width: 920px) {
  .container {
    width: 560px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 561px) {
  .container {
    width: 500px;
  }
}

@media screen and (max-width: 526px) {
  .container {
    width: 300px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .logo svg {
    width: 31px;
    height: 31px;
  }

  .text {
    font-size: 15px;
  }

  .amount {
    font-size: 16px;
  }
}
