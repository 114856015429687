.container {
  width: 744px;
  height: 248px;
  background: var(--background);
  border-radius: 4px;
  margin-top: 36px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #01a6f0;
}

.header {
  width: 100%;
  height: 99px;
  background: linear-gradient(267.27deg, #01a6f0 1.57%, #0047ff 98.69%);
  border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  align-items: center;
  padding: 29px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.logo,
.logo svg {
  width: 44px;
  height: 44px;
}

.title,
.index {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
  color: var(--color);
  margin-left: 18px;
}

.horizontal {
  display: flex;
  flex-direction: row;
  height: 44px;
  align-items: center;
}

.button {
  margin-left: 10px;
  width: 96px;
  height: 42px;
  background: #0047ff;
  border: none;
  border-radius: 4px;
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
}

.section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: var(--color);
  margin-top: 17px;
}

.content {
  padding: 12px 28px 0 31px;
}

.line {
  width: 100%;
  margin-top: 26px;
  border-bottom: 1px solid #15a5df;
}

.timer {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
  margin-left: 6px;
}

.shimmer {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin: 0 auto;
  display: inline;
  margin-bottom: 0;
  margin-left: 10px;
}
.shimmer {
  text-align: center;
  color: rgba(255, 255, 255, 0.1);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  background: -moz-gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  background: gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  -webkit-background-size: 25px 100%;
  -moz-background-size: 75px 100%;
  background-size: 75px 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-animation-name: shimmer;
  -moz-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: #222;
}
@-moz-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-webkit-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-o-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

@media screen and (max-width: 920px) {
  .container {
    width: 560px;
  }
}

@media screen and (max-width: 561px) {
  .container {
    width: 400px;
  }

  .shimmer {
    font-size: 16px;
  }
}

@media screen and (max-width: 526px) {
  .container {
    width: 300px;
  }

  .header {
    height: 105px;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 15px;
    position: relative;
  }

  .logo,
  .logo svg {
    width: 33px;
    height: 33px;
  }

  .title,
  .index {
    font-size: 16px;
  }

  .title {
    margin-left: 7px;
  }

  .index {
    position: absolute;
    z-index: 2;
    color: #ffffff;
    top: 57px;
  }

  .button {
    position: relative;
    width: 273px;
    height: 36px;
    margin-top: 6px;
    font-size: 14px;
  }

  .timer {
    background: #0047ff;
    border: none;
    border-radius: 4px;
    position: relative;
    width: 273px;
    height: 36px;
    margin-top: 6px;
    font-size: 14px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
