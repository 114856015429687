body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--bg) !important;
  min-height: 100vh;
  overflow-y: scroll;
  overflow: overlay;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (max-width: 520px) {
  body {
    min-height: 100%;
    overflow: none;
  }

  body::-webkit-scrollbar-track {
    border-radius: 0;
    -webkit-border-radius: 0;
    background-clip: padding-box;
  }

  body::-webkit-scrollbar {
    width: 0;
    background: transparent !important;
    background-color: transparent !important;
    height: 0;
    position: relative;
    right: 0;
  }

  body::-webkit-scrollbar-thumb {
    width: 0;
    height: 0 !important;
    background-color: #d5d7db;
    -webkit-border-radius: 0px;
    border-radius: 0;
    background-clip: padding-box;
    border: solid 0 transparent;
  }
}

@media screen and (min-width: 520px) {
  body::-webkit-scrollbar-track {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    background-clip: padding-box;
  }

  body::-webkit-scrollbar {
    width: 26px;
    background: transparent !important;
    background-color: transparent !important;
    height: 30px;
    position: relative;
    right: 5px;
  }

  body::-webkit-scrollbar-thumb {
    width: 5px;
    height: 75px !important;
    background-color: #d5d7db;
    -webkit-border-radius: 4px;
    border-radius: 500px;
    background-clip: padding-box;
    border: solid 10px transparent;
  }
}

html {
  --bg: linear-gradient(
    360deg,
    #d8e3ff 0%,
    #dce6ff 0%,
    #fbfcff 51.48%,
    #f6f9ff 100%
  );
  --background: linear-gradient(
    180.3deg,
    rgba(215, 226, 255, 0) -6.44%,
    #e4ecff 99.74%
  );
  --bg3: linear-gradient(
    #f5f9fe;
  );
  --color: #08142a;
  --bg-color: #0047ff;
  --font-color: #1b1a19;
  --font-color2: #808080;
  --font-color3: #4d4d4d;
  --fill-color: #1b1a19;
  --text-bg: linear-gradient(267.27deg, #01a6f0 1.57%, #0047ff 98.69%);
  --modal-bg: linear-gradient(
    180.3deg,
    rgba(215, 226, 255, 0) -6.44%,
    #e4ecff 99.74%
  );
  --font-color4: #171717;
  --hamburger: #02288b;
  --bg2: #fff;
  --buttonBg: transparent;
  --buttonFont: #0047ff;
  --border-color: #e0e0e0;
  --text-color3: #57606a;
  --border-hover: #57606a;
  --primary-color: #384aff;
}

html[theme="dark"] {
  --bg: #211f24;
  --background: #08142a;
  --bg3: #211f24;
  --color: #fff;
  --bg-color: #01a6f0;
  --font-color: #ecedf0;
  --font-color2: #d1d1d1;
  --font-color3: #fff;
  --fill-color: #d1d1d1;
  --text-bg: linear-gradient(360deg, #e8efff 0%, #fbfcff 51.48%, #f6f9ff 100%);
  --modal-bg: linear-gradient(315deg, #485461 0%, #28313b 74%);
  --font-color4: #d1d1d1;
  --hamburger: #fbfcff;
  --bg2: #485461;
  --buttonBg: linear-gradient(267.27deg, #0047ff 1.57%, #01a6f0 98.69%);
  --buttonFont: #ecedf0;
  --text-color3: #fff;
  --border-color: #57606a;
  --border-hover: #e0e0e0;
}
