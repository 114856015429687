.container {
  height: auto;
}

.empty {
  height: auto;
  margin: 300px auto 400px auto;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #1b1a19;
}

.items {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 210px;
}

@media screen and (max-width: 920px) {
  .items {
    margin-bottom: 240px;
  }
}

@media screen and (max-width: 526px) {
  .items {
    margin-bottom: 193px;
  }
}
