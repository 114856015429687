.container {
  display: flex;
  flex-direction: row;
  height: 25px;
  align-items: center;
}

.title {
  margin-left: 11px;
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: var(--font-color);
  height: 15px;
  margin-top: 3px;
}

.date {
  margin-left: 11px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: var(--font-color);
  height: 15px;
}

.logo svg path {
  fill: var(--bg-color);
}

@media screen and (max-width: 920px) {
  .title,
  .date {
    font-size: 14px;
  }
}

@media screen and (max-width: 526px) {
  .title,
  .date {
    font-size: 12px;
  }

  .container {
    width: 240px;
  }
}
