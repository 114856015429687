.button {
  width: 885px;
  height: 56px;
  margin: 33px auto 0 auto;
  background: linear-gradient(180deg, #0047ff 0%, #002073 100%),
    linear-gradient(270.01deg, #0047ff 0.01%, #002073 100%);
  border-radius: 4px;
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
  border: none;
}

.disabledButton {
  width: 885px;
  height: 56px;
  margin: 33px auto 0 auto;
  background: #5f6a69;
  border: 1px solid #94acaa;
  border-radius: 4px;
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.button:hover {
  background: linear-gradient(267.27deg, #01a6f0 1.57%, #0047ff 98.69%);
}

.shimmer {
  text-align: center;
  color: rgba(255, 255, 255, 0.1);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fff),
    to(#fff),
    color-stop(0.5, #222)
  );
  background: -moz-gradient(
    linear,
    left top,
    right top,
    from(#fff),
    to(#fff),
    color-stop(0.5, #222)
  );
  background: gradient(
    linear,
    left top,
    right top,
    from(#fff),
    to(#fff),
    color-stop(0.5, #222)
  );
  -webkit-background-size: 25px 100%;
  -moz-background-size: 75px 100%;
  background-size: 75px 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-animation-name: shimmer;
  -moz-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: #fff;
}
@-moz-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-webkit-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-o-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

@media screen and (max-width: 920px) {
  .button {
    width: 560px;
  }
  .disabledButton {
    width: 560px;
  }
}

@media screen and (max-width: 590px) {
  .button {
    width: 460px;
  }
  .disabledButton {
    width: 460px;
  }
  .shimmer {
    font-size: 16px;
  }
}

@media screen and (max-width: 526px) {
  .button {
    width: 256px;
    height: 40px;
  }
  .disabledButton {
    width: 256px;
    height: 40px;
  }
}
