.container {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
}

.back {
  position: absolute;
  top: 29px;
  left: 50px;
  cursor: pointer;
}

.title {
  margin-top: 32px;
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
  text-align: center;
  background: var(--text-bg);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.balance {
  position: absolute;
  top: 39px;
  right: 50px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: var(--color);
}

.logo svg {
  width: 44px;
  height: 44px;
}

.back svg path {
  fill: var(--color);
}

.amount {
  padding: 17px 34px 17px 38px;
  margin: 67px auto 0 auto;
  align-items: center;
  max-width: 210px;
  width: auto;
  height: 78px;
  filter: drop-shadow(0px 0px 8px rgba(172, 172, 172, 0.25));
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #0047ff;
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  background: linear-gradient(267.27deg, #01a6f0 1.57%, #0047ff 98.69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.term {
  font-size: 20px;
  font-weight: bold;
  margin-top: 30px;
}

.cards {
  margin: 38px auto 0 auto;
  width: 484px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.messageContainer {
  margin-top: 31px;
  margin-left: auto;
  margin-right: auto;
  width: 484px;
  height: 52px;
  align-items: center;
  border-radius: 11px;
  display: flex;
  flex-direction: row;
  text-align: left;
  padding-left: 16.25px;
  padding-right: 14px;
}

.message {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #e42c58;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.buttonContainer {
  width: 434px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 95px auto 71px auto;
}

.cancel {
  width: 207px;
  height: 56px;
  background: #e42c3b;
  border-radius: 4px;
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  border: none;
}

.cancel:hover {
  box-shadow: 0px 0px 12px #e42c3b;
}

.accept {
  width: 207px;
  height: 56px;
  background: #0047ff;
  border-radius: 4px;
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  border: none;
}

.accept:hover {
  box-shadow: 0px 0px 12px #60c2ff;
}

.shimmer {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin: 0 auto;
  display: inline;
  margin-bottom: 0;
}
.shimmer {
  text-align: center;
  color: rgba(255, 255, 255, 0.1);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  background: -moz-gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  background: gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  -webkit-background-size: 25px 100%;
  -moz-background-size: 75px 100%;
  background-size: 75px 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-animation-name: shimmer;
  -moz-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: #222;
}
@-moz-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-webkit-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-o-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

@media screen and (max-width: 920px) {
  .back {
    left: 21px;
  }

  .balance {
    right: 29px;
  }

  .buttonContainer {
    width: 484px;
    margin-top: 31px;
    margin-bottom: 45px;
  }

  .accept,
  .cancel {
    width: 232px;
  }
}

@media screen and (max-width: 526px) {
  .back svg {
    width: 24px;
    height: 24px;
  }

  .back {
    left: 17px;
  }

  .balance {
    display: none;
  }

  .amount {
    width: 184px;
    height: 60.81px;
    font-size: 24px;
    margin-top: 33px;
    padding-left: 29px;
    padding-right: 26px;
  }

  .logo svg {
    width: 34.31px;
    height: 34.31px;
  }

  .shimmer {
    font-size: 16px;
  }

  .title {
    font-size: 20px;
  }

  .cards {
    width: 256px;
    flex-wrap: wrap;
  }

  .messageContainer {
    width: 256px;
    height: 120px;
  }

  .buttonContainer {
    width: 201px;
    height: 98px;
    flex-direction: column;
    margin-bottom: 26px;
  }

  .accept,
  .cancel {
    width: 200px;
    height: 40px;
  }
}
