.container {
  height: auto;
  text-align: center;
  align-items: center;
  padding-bottom: 50px;
}

.text {
  margin: 50px auto 0 auto;
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: var(--font-color);
}

.button {
  width: 322px;
  height: 56px;
  background: #0047ff;
  border-radius: 4px;
  margin: 70px auto 0 auto;
  border: none;
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

.button:hover {
  box-shadow: 0px 0px 12px #15a5df;
}

@media screen and (max-width: 530px) {
  .button {
    width: 269px;
    font-size: 16px;
  }

  .text {
    font-size: 16px;
  }
}
