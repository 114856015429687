.amountContainer {
  width: 886px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px auto 0 auto;
}

.calendarContainer {
  margin: 25px auto 0 auto;
  width: 886px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 23px;
  padding-right: 129px;
}

.cardList {
  width: 885px;
  margin: 37px auto 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttonContainer {
  width: 664px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 71px auto 170px auto;
}

@media screen and (max-width: 920px) {
  .amountContainer {
    width: 560px;
  }

  .cardList {
    width: 560px;
    flex-wrap: wrap;
  }

  .calendarContainer {
    width: 560px;
    padding: 0px;
  }

  .buttonContainer {
    width: 322px;
    height: 139px;
    flex-direction: column;
  }
}

@media screen and (max-width: 560px) {
  .amountContainer {
    width: 500px;
  }

  .cardList {
    width: 500px;
    flex-wrap: wrap;
  }

  .calendarContainer {
    width: 500px;
    padding: 0px;
  }

  .buttonContainer {
    width: 322px;
    height: 139px;
    flex-direction: column;
  }
}

@media screen and (max-width: 526px) {
  .amountContainer {
    width: 300px;
    height: 166px;
    justify-content: space-between;
    flex-direction: column;
  }

  .calendarContainer {
    width: 240px;
    height: 58px;
    flex-direction: column;
  }

  .cardList {
    width: 300px;
    flex-wrap: wrap;
  }

  .buttonContainer {
    width: 300px;
    height: 102px;
  }
}
