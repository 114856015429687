.container {
  width: 100%;
  height: 56px;
  margin-bottom: 70px;
  padding-left: 80px;
  padding-right: 80px;
}

.line {
  border-top: 1px solid;
  border-image: linear-gradient(
      270deg,
      rgba(21, 165, 223, 0.19) 0%,
      #15a5df 56.25%,
      rgba(21, 165, 223, 0.19) 100%
    )
    10;
}

.logo svg {
  width: 163px;
  height: 25.56px;
}

.horizontal {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.powered {
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 24px;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  color: var(--font-color2);
}

.text {
  margin-top: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: var(--font-color2);
}

.openMoney {
  margin-left: 12px;
}

@media screen and (max-width: 920px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .logo svg {
    width: 132px;
    height: 20px;
  }

  .text {
    font-size: 10px;
  }

  .openMoney svg {
    width: 51px;
    height: 20px;
  }

  .powered {
    font-size: 10px;
  }
}

@media screen and (max-width: 526px) {
  .horizontal {
    flex-direction: column;
  }

  .powered {
    margin-top: 19px;
    flex-direction: column;
  }

  .openMoney {
    margin-top: 6px;
    margin-left: 0;
  }

  .text {
    margin-top: 10px;
  }
  .container {
    height: auto;
  }
}
