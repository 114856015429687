.container {
  width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.numbers {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.number {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
  cursor: pointer;
  font-size: 18px;
  float: left;
  margin: 0px 6px;
  padding: 8px 16px;
  text-decoration: none;
  border: 1px solid #01A6F0;
  border-radius: 10px;
  background-color: #fff;
}

.number:first-child {
  margin-left: 0px;
}

.number:hover {
  background: linear-gradient(267.27deg, #01a6f0 1.57%, #0047ff 98.69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}