.header img {
  width: 940px;
  height: 350px;
  border-radius: 4px;
}

.container {
  margin-top: 60px;
  margin-bottom: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1012px;
  margin: auto;
  min-height: 100vh;
}

.contentOne {
  width: 642px;
  font-family: "Nunito";
}

.info {
  width: 100%;
  margin: auto;
}

.horizontal {
  display: flex;
  flex-direction: row;
}

.date {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 36px;
  margin-left: 10px;
}

.dateTitle {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 36px;
}

.title {
  font-family: "Nunito";
  font-style: normal;
  font-size: 36px;
  line-height: 50px;
  text-align: left;
  color: var(--font-color);
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-wrap: break-word;
  word-break: break-word;
  font-weight: 600;
}

.status {
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  height: 26px;
  vertical-align: middle;
  padding: 0 12px;
  border-radius: 14px;
  line-height: 24px;
  background-color: #21b66f;
  color: #fff;
  width: fit-content;
  min-width: 64px;
  height: 26px;
  margin-top: 20px;
}

.endStatus {
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  height: 26px;
  vertical-align: middle;
  padding: 0 12px;
  border-radius: 14px;
  line-height: 24px;
  background-color: #384aff;
  color: #fff;
  width: fit-content;
  min-width: 64px;
  height: 26px;
  margin-top: 20px;
}

.pendingStatus {
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  height: 26px;
  vertical-align: middle;
  padding: 0 12px;
  border-radius: 14px;
  line-height: 24px;
  background-color: #ff8138;
  color: #fff;
  width: fit-content;
  min-width: 64px;
  height: 26px;
  margin-top: 20px;
}

.detailTitle {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.4 !important;
  font-size: 1.5em;
  display: flex;
  color: var(--font-color);
}

.text {
  font-family: "Nunito";
  font-style: normal;
  font-size: 18px;
  line-height: 1.6;
  font-weight: 500;
  color: var(--font-color3);
  text-align: left;
  margin-top: 30px;
  overflow-wrap: break-word;
  word-break: break-word;
  transition: all 0.5 ease-in-out;
}

.voteTitle {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: var(--font-color);
  display: flex;
  padding-left: 20px;
}

.more {
  border: 1px solid var(--border-color);
  background-color: transparent;
  border-radius: 23px;
  height: 46px;
  font-size: 18px;
  width: 124px;
  height: 46px;
  margin-top: 30px;
}

.more:hover {
  border: 1px solid var(--border-hover);
}

.skin {
  --tw-gradient-from: transparent;
  --tw-gradient-to: rgb(255 255 255 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
  height: 80px;
  bottom: 0px;
  position: absolute;
}

.vote {
  margin-top: 30px;
  width: 100%;
  border: 1px solid var(--border-color);
  height: auto;
  border-radius: 0.75rem;
  padding-top: 20px;
  font-family: "Nunito";
  color: var(--font-color);
}

.voteR {
  border-bottom: 1px solid var(--border-color);
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
  height: 56px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.moreVote {
  height: 56px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: row;
  font-family: "Nunito";
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.voteText {
  font-family: "Nunito";
  display: flex;
  height: 18px;
  align-items: center;
}

.voteAdr {
  margin-left: 5px;
  font-size: 16px;
  font-weight: 600;
}

.voteR:last-child {
  border-bottom: 0px;
}

.voteImg {
  width: 18px;
  height: 18px;
  min-width: 18px;
  border-radius: 9999px;
}

.contentTwo {
  width: 321px;
}

.infoCard {
  margin-top: 30px;
  width: 100%;
  border: 1px solid var(--border-color);
  height: auto;
  border-radius: 0.75rem;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: "Nunito";
  color: var(--font-color);
}

.infoText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
}

.options {
  padding-left: 24px;
  padding-right: 24px;
}

.option {
  border-radius: 40px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: var(--font-color);
  cursor: pointer;
  border: 1px solid var(--border-color);
  background-color: transparent;
  border-radius: 23px;
  height: 46px;
  font-size: 18px;
  width: 100%;
}

.optionDis {
  border-radius: 40px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: var(--font-color);
  cursor: pointer;
  border: 1px solid var(--border-color);
  background-color: var(--border-color);
  border-radius: 23px;
  height: 46px;
  font-size: 18px;
  width: 100%;
  cursor: not-allowed;
}

.option:hover {
  border: 1px solid var(--border-hover);
}

.option2 {
  border-radius: 40px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: var(--font-color);
  cursor: pointer;
  border: 1px solid var(--border-color);
  background-color: var(--primary-color);
  border-radius: 23px;
  height: 46px;
  font-size: 18px;
  width: 100%;
  color: #fff;
}

.option2:hover {
  border: 1px solid var(--border-hover);
}

.selectedOption {
  border-radius: 40px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 18px;
  color: var(--font-color);
  cursor: pointer;
  border: 1px solid var(--border-hover);
  background-color: transparent;
  border-radius: 23px;
  height: 46px;
  font-size: 18px;
  width: 100%;
}

.bar {
  margin: auto;
}

.progress {
  width: 100%;
  margin: 20px auto;
  padding-left: 24px;
  padding-right: 24px;
}

.barText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 139.9%;
  color: var(--font-color3);
}

.alert {
  color: var(--fill-color);
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 139.9%;
  margin-top: 50px;
}

.empty {
  height: auto;
  margin: 80px auto 400px auto;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: var(--font-color);
}

.shimmer {
  text-align: center;
  color: rgba(255, 255, 255, 0.1);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  background: -moz-gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  background: gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  -webkit-background-size: 25px 100%;
  -moz-background-size: 75px 100%;
  background-size: 75px 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-animation-name: shimmer;
  -moz-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: #222;
}
@-moz-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-webkit-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-o-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

@media screen and (max-width: 1160px) {
  .contentOne {
    width: 610px;
  }
  .content {
    width: 920px;
  }
}

@media screen and (max-width: 950px) {
  .content {
    flex-direction: column;
  }

  .contentOne,
  .contentTwo {
    width: 80%;
    margin: auto;
  }
}

@media screen and (max-width: 936px) {
  .content {
    width: 80%;
  }

  .alert {
    width: 100%;
    margin-top: 30px;
  }
}

@media screen and (max-width: 620px) {
  .header img {
    width: 345px;
    height: 250px;
  }

  .title {
    font-size: 21px;
    line-height: 30px;
    flex-direction: column;
  }

  .date {
    font-size: 12px;
    line-height: 20px;
    margin-top: 6px;
  }

  .dateTitle {
    font-size: 12px;
    line-height: 20px;
    margin-top: 6px;
  }

  .text {
    font-size: 14px;
    line-height: 27px;
  }

  .barText {
    width: 203px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 561px) {
  .empty {
    font-size: 18px;
  }

  .shimmer {
    font-size: 16px;
  }

  .content {
    width: 100%;
  }

  .infoCard,
  .vote {
    font-size: 14px;
  }

  .voteAdr {
    font-size: 13px;
  }
}
