.container {
  width: 744px;
  height: 217px;
  border-radius: 4px;
  margin-top: 36px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid var(--border-color);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 16px;
  position: relative;
  text-align: left;
}

.container:hover {
  border: 1px solid var(--border-hover);
}

.header {
  width: 100%;
  border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 600;
}

.description {
  color: #8b949e;
  font-size: 18px;
  line-height: 25px;
  font-family: "Nunito";
  margin-top: 15px;
}

.status {
  font-size: 16px;
  height: 26px;
  vertical-align: middle;
  padding: 0 12px;
  border-radius: 14px;
  line-height: 24px;
  background-color: #21b66f;
  color: #ffffff;
  position: absolute;
  right: 20px;
  top: 20px;
}

.endStatus {
  font-size: 16px;
  height: 26px;
  vertical-align: middle;
  padding: 0 12px;
  border-radius: 14px;
  line-height: 24px;
  background-color: #384aff;
  color: #ffffff;
  position: absolute;
  right: 20px;
  top: 20px;
}

.pendingStatus {
  font-size: 16px;
  height: 26px;
  vertical-align: middle;
  padding: 0 12px;
  border-radius: 14px;
  line-height: 24px;
  background-color: #ff8138;
  color: #ffffff;
  position: absolute;
  right: 20px;
  top: 20px;
}

.logo,
.logo svg {
  width: 44px;
  height: 44px;
}

.bottom {
  display: flex;
  position: absolute;
  bottom: 20px;
  color: #8b949e;
}

.title,
.index {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
  color: var(--color);
  margin-left: 18px;
}

.horizontal {
  display: flex;
  flex-direction: row;
  height: 44px;
  align-items: center;
}

.button {
  margin-left: 10px;
  width: 96px;
  height: 42px;
  background: #0047ff;
  border: none;
  border-radius: 4px;
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: "Nunito";
  font-style: normal;
  color: var(--text-color3);
  font-size: 18px;
  line-height: 25px;
}

.text {
  font-size: 18px;
  color: black;
  font-weight: 600;
}

.line {
  width: 100%;
  margin-top: 26px;
  border-bottom: 1px solid #15a5df;
}

@media screen and (max-width: 920px) {
  .container {
    width: 560px;
  }
}

@media screen and (max-width: 561px) {
  .container {
    width: 400px;
  }
}

@media screen and (max-width: 526px) {
  .container {
    width: 300px;
    overflow: hidden;
  }

  .header {
    position: relative;
  }

  .status,
  .endStatus,
  .pendingStatus {
    right: -3px;
    top: -1px;
  }

  .description {
    max-height: 56px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .logo,
  .logo svg {
    width: 33px;
    height: 33px;
  }

  .title,
  .index {
    font-size: 16px;
  }

  .title {
    margin-left: 7px;
  }

  .index {
    position: absolute;
    z-index: 2;
    color: #ffffff;
    top: 57px;
  }

  .button {
    position: relative;
    width: 273px;
    height: 36px;
    margin-top: 6px;
    font-size: 14px;
  }
}
