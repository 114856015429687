.text {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 33px;
  text-align: center;
  background: var(--text-bg);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-top: 60px;
}

.smallText {
  margin-top: 29px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: var(--font-color);
}

@media screen and (max-width: 920px) {
  .text {
    width: 365px;
    margin-left: auto;
    margin-right: auto;
    font-size: 24px;
  }

  .smallText {
    font-size: 21px;
  }
}

@media screen and (max-width: 526px) {
  .text {
    width: 281px;
  }
}
