.container {
  width: 45%;
  height: 300px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 550px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.image {
  height: 100%;
  overflow: hidden;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  max-height: 100%;
  max-width: 100%;
  position: relative;
  padding-left: 40px;
  padding-right: 40px;
}

.image img {
  width: 100%;
  height: 100%;
  border-radius: initial;
}

@media screen and (max-width: 820px) {
  .container {
    min-height: auto;
  }
}

@media screen and (max-width: 1112px) {
  .container {
    width: 100%;
  }
}
