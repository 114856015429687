.container {
  width: 885px;
  height: 282px;
  margin: 26px auto 0 auto;
  background: var(--background);
  border-radius: 4px;
  border: 1px solid #01a6f0;
  padding-top: 45px;
}

.title {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #01a6f0;
}

.amount {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
  color: var(--color);
  margin-top: 17px;
}

.logoContainer {
  width: 89px;
  height: 29px;
  align-items: center;
  margin: 10px auto 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.logo svg {
  width: 29px;
  height: 29px;
}

.omc {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  color: var(--color);
}

.buttonContainer {
  width: 584px;
  height: 56px;
  margin: 32px auto 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 920px) {
  .container {
    width: 560px;
    height: 358px;
  }

  .buttonContainer {
    width: 100%;
    height: 142px;
    flex-direction: column;
  }
}

@media screen and (max-width: 590px) {
  .container {
    width: 460px;
    padding-top: 30px;
  }
}

@media screen and (max-width: 526px) {
  .container {
    width: 300px;
    height: 255px;
    padding-top: 18px;
  }

  .title {
    font-size: 16px;
  }

  .amount {
    font-size: 20px;
  }

  .logo svg {
    width: 23px;
    height: 23px;
  }

  .omc {
    font-size: 18px;
  }

  .logoContainer {
    width: 76px;
  }

  .buttonContainer {
    height: 90px;
    margin-top: 24px;
  }
}
