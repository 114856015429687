.title {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  color: var(--font-color);
}

.container {
  margin-bottom: 170px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.empty {
  height: auto;
  margin: 80px auto 400px auto;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: var(--font-color);
}

.header {
  width: 744px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 561px) {
  .empty {
    font-size: 18px;
  }
}

@media screen and (max-width: 920px) {
  .header {
    width: 560px;
    display: block;
  }
}

@media screen and (max-width: 561px) {
  .header {
    width: 400px;
  }
}

