.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-left: 60px;
  padding-right: 60px;
  margin-bottom: 40px;
  margin-top: 60px;
  font-family: "Nunito";
  color: var(--font-color);
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.title {
  font-family: "Nunito";
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px;
  display: flex;
  text-align: left;
  word-break: break-word;
}

.horizontal {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.contentS {
  width: 50%;
}

.contentB {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.shimmer {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin: auto;
  display: inline;
  margin-top: 150px;
  margin-bottom: 390px;
  height: 50px;
}
.shimmer {
  text-align: center;
  color: rgba(255, 255, 255, 0.1);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  background: -moz-gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  background: gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  -webkit-background-size: 25px 100%;
  -moz-background-size: 75px 100%;
  background-size: 75px 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-animation-name: shimmer;
  -moz-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: #222;
}
@-moz-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-webkit-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-o-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

.empty {
  height: auto;
  margin: 80px auto 400px auto;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: var(--font-color);
}

.accept {
  width: 207px;
  height: 56px;
  background: #0047ff;
  border-radius: 4px;
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  border: none;
  margin-top: 20px;
}

.accept:hover {
  box-shadow: 0px 0px 12px #60c2ff;
}

@media screen and (max-width: 1112px) {
  .container {
    flex-direction: column;
  }

  .content {
    width: 100%;
    flex: 1;
  }

  .contentS {
    width: 100%;
    flex: 1;
  }

  .contentLeft {
    width: 100%;
    flex-direction: column-reverse;
  }

  .horizontal {
    flex-direction: column;
  }

  .title {
    margin-left: 0px;
  }
}
