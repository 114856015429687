.container {
  width: 508px;
  height: 259px;
  padding: 22px 58px 22px 58px;
  background: var(--bg3);
  border-radius: 4px;
  border: 1px solid #01a6f0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.icon svg {
  box-shadow: 0px 0px 8px #01a4f3;
  border-radius: 50%;
}

.sectionText {
  height: 63px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  background: linear-gradient(267.27deg, #01a6f0 1.57%, #0047ff 98.69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.text {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
  color: var(--color);
}

.firstSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sectionIcon {
  width: 149px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.secondSection {
  margin-top: 29px;
  height: 22px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.progressText {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #01a6f0;
}

.thirdSection {
  margin-top: 10px;
}

.supplyContainer {
  width: 100%;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.supplyTitle {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: var(--color);
}

.supplyText {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
  color: var(--color);
}

@media screen and (max-width: 920px) {
  .container {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 526px) {
  .container {
    width: 300px;
    padding: 13px 34px 13px 34px;
  }

  .title {
    font-size: 12px;
  }

  .text {
    font-size: 14px;
  }

  .sectionIcon div svg {
    width: 28px;
    height: 28px;
  }

  .sectionIcon {
    width: 94px;
  }

  .progressText {
    font-size: 10px;
  }

  .secondSection {
    margin-top: 20px;
  }

  .supplyTitle {
    font-size: 12px;
  }

  .supplyText {
    font-size: 12px;
  }
}
