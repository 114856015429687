.statusContainer {
  margin-top: 26px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.title {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
  color: var(--color);
}

.arrow {
  width: 84px;
  height: 84px;
  margin: 24px auto 0 auto;
}
.arrowActive svg path {
  fill: #4d4d4d;
}

.message {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 139.9%;
  color: #e42c58;
}

.shimmer {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin: auto;
  display: inline;
  margin-top: 300px;
  margin-bottom: 390px;
  height: 50px;
}
.shimmer {
  text-align: center;
  color: rgba(255, 255, 255, 0.1);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  background: -moz-gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  background: gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  -webkit-background-size: 25px 100%;
  -moz-background-size: 75px 100%;
  background-size: 75px 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-animation-name: shimmer;
  -moz-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: #222;
}
@-moz-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-webkit-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-o-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

@media screen and (max-width: 526px) {
  .arrow svg {
    width: 51px;
    height: 51px;
  }

  .arrowActive svg {
    width: 51px;
    height: 51px;
  }

  .title {
    font-size: 20px;
  }
}
