.App {
  text-align: center;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  box-sizing: border-box;
}

button {
  cursor: pointer;
}

.progress-bar {
  background: linear-gradient(89.92deg, #0047ff 8.37%, #029efc 121.63%);
}

.progress {
  width: 100% !important;
  height: 11px !important;
  border-radius: 19px !important;
  background-color: var(--border-color) !important;
}

.modal-content {
  width: 584px !important;
  height: auto !important;
  border-radius: 4px !important;
  background-clip: padding-box !important;
  background-image: var(--modal-bg) !important;
  border: 1px solid #0047ff !important;
}

.modal-dialog {
  max-width: 584px !important;
}

.socialBar {
  position: fixed;
  height: 234px;
  right: 30px;
  margin-top: 235px;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: auto !important;
  }

  .modal-content {
    width: 554px !important;
  }
}

@media screen and (max-width: 526px) {
  .progress {
    width: 203px !important;
    height: 6px !important;
  }

  .modal-dialog {
    width: 300px !important;
    margin: auto !important;
  }

  .modal-content {
    width: 300px !important;
  }

  .socialBar {
    width: 270px;
    margin-left: auto;
    margin-right: auto;
    bottom: -60px;
    right: 0;
    left: 0;
    position: absolute;
    height: auto;
  }
}

@media (min-width: 526px) and (max-width: 920px) {
  .modal-dialog {
    max-width: auto !important;
  }

  .modal-content {
    width: 554px !important;
  }

  .socialBar {
    width: 270px;
    margin-left: auto;
    margin-right: auto;
    bottom: -40px;
    right: 0;
    left: 0;
    position: absolute;
    height: auto;
  }
}

@media (min-width: 920px) and (max-width: 1015px) {
  .socialBar {
    width: 270px;
    margin-left: auto;
    margin-right: auto;
    bottom: -40px;
    right: 0;
    left: 0;
    position: absolute;
    height: auto;
  }
}
