.container {
  width: 100%;
  padding-left: 80px;
  padding-right: 80px;
  height: 62px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.toggle svg {
  width: 40px;
  height: 40px;
}

.toggle {
  background-color: transparent;
  border: none;
}

.light path {
  fill: #fff;
}

.logo {
  width: 236px;
  height: 38px;
  cursor: pointer;
}

.logo svg {
  width: 236px;
  height: 38px;
  cursor: pointer;
}

.horizontal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.links {
  width: 650px;
  height: 38px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.link {
  width: 131px;
  height: 38px;
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: var(--font-color);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.linkActive {
  width: 131px;
  height: 38px;
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(
    180deg,
    rgba(61, 129, 255, 0.79) 0%,
    #0047ff 100%
  );
  border-radius: 4px;
  transition: 500ms all;
}

.linkMobile {
  height: 70px;
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: var(--font-color);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-image: linear-gradient(
      270deg,
      rgba(21, 165, 223, 0.19) 0%,
      #15a5df 56.25%,
      rgba(21, 165, 223, 0.19) 100%
    )
    10;
}

.linkMobile:last-child {
  border-bottom: none;
}

.button {
  width: 236px;
  display: flex;
  flex-direction: row;
  height: 44px;
  align-items: center;
}

.hamburger button {
  display: none;
  height: 44px;
  background-color: transparent;
  border: none;
  margin-left: 5px;
}

.hamburger button svg path {
  fill: var(--hamburger);
}

.mobileLinks {
  display: none;
  width: 100%;
  position: absolute;
  z-index: 2;
  right: 0;
  top: 60px;
  flex-direction: column;
  height: 686px;
  background-color: var(--bg2);
  padding-top: 35px;
  border-top: 1px solid;
  border-image: linear-gradient(267.27deg, #01a6f0 1.57%, #0047ff 98.69%);
}

.mobileList {
  width: 220px;
  margin: auto;
}

@media screen and (max-width: 1020px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 1320px) {
  .container {
    padding-left: 20px;
    padding-right: 11px;
  }
  .mobileLinks {
    display: flex;
  }

  .links {
    display: none;
  }

  .hamburger button {
    display: block;
  }

  .logo svg {
    width: 207px;
    height: 32px;
  }

  .button {
    width: inherit;
  }
}

@media screen and (max-width: 526px) {
  .logo svg {
    width: 128px;
    height: 20px;
  }

  .toggle svg {
    width: 30px;
    height: 30px;
    margin-left: 10px;
  }

  .logo {
    width: 128px;
    height: 20px;
  }

  .hamburger button svg {
    width: 37px;
    height: 37px;
  }

  .hamburger button {
    margin-left: 0px;
  }

  .horizontal {
    align-items: center;
  }
}
