.container {
  position: relative;
  width: 885px;
  height: 330px;
  background: var(--background);
  border-radius: 4px;
  margin: 20px auto 70px auto;
  padding-top: 26px;
  border: 1px solid #01a6f0;
}

.networkContainer {
  width: 133px;
  margin: auto;
}

.networkLogo {
  width: 60px;
  height: 60px;
  background: #c4c4c4;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}

.networkText {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 139.9%;
  color: var(--bg-color);
  margin-top: 12px;
}

.timerContainer {
  position: absolute;
  top: 47px;
  right: 79px;
  display: flex;
  flex-direction: row;
}

.timerText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: var(--font-color3);
}

.time {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: var(--color);
  margin-left: 9px;
}

.statusContainer {
  width: 584px;
  height: 97px;
  margin: 37px auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.iconContainer {
  width: 474px;
  margin: 4px auto 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.statusTimerContainer {
  width: 342px;
  margin: 0px auto 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
}
.textContainer {
  width: 400px;
  margin: 25px auto 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.status {
  display: flex;
  flex-direction: column;
}

.statusIcon {
  width: 18px;
  height: 18px;
  border: solid 2px transparent;
  background-image: linear-gradient(#01a6f0, #0047ff),
    linear-gradient(101deg, #01a6f0, #0047ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
  -webkit-appearance: none;
  border-radius: 50%;
  margin: 4px auto 0 auto;
  position: relative;
  z-index: 2;
}

.statusIconActive {
  width: 18px;
  height: 18px;
  background: linear-gradient(180deg, #0047ff 0%, #002073 100%);
  border-radius: 50%;
  margin: 4px auto 0 auto;
  position: relative;
  z-index: 2;
}

.statusIconTwo {
  width: 18px;
  height: 18px;
  margin: 4px auto 0 auto;
  position: relative;
  z-index: 2;
}

.statusTimer {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #171717;
}

.statusText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: var(--font-color4);
  width: 82px;
}

.line {
  width: 310px;
  border-top: 2px solid #808080 !important ;
  margin-top: 26px;
  position: absolute;
  z-index: 1;
  top: -13px;
  left: 78px;
}

.lineActive {
  width: 310px;
  border-top: solid 2px transparent;
  background-image: linear-gradient(#01a6f0, #0047ff),
    linear-gradient(101deg, #01a6f0, #0047ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
  -webkit-appearance: none;
  margin-top: 26px;
  position: absolute;
  z-index: 1;
  top: -13px;
  left: 78px;
}

@media screen and (max-width: 920px) {
  .container {
    width: 560px;
    height: 308px;
  }

  .timerContainer {
    right: 41px;
  }

  .statusContainer {
    width: 100%;
  }
}

@media screen and (max-width: 590px) {
  .container {
    width: 460px;
    height: 308px;
  }

  .timerContainer {
    right: 41px;
  }

  .statusContainer {
    width: 100%;
  }
}

@media screen and (max-width: 526px) {
  .container {
    width: 300px;
    height: 415px;
    padding-top: 12px;
  }

  .networkContainer {
    width: 205px;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 51px 0px 44px;
  }

  .networkText {
    width: 95px;
    margin-top: 0;
    margin-left: 15px;
    text-align: left;
  }
  .networkLogo {
    margin-left: 0;
    margin-right: 0;
  }

  .timerContainer {
    top: inherit;
    bottom: 38px;
    left: 54px;
  }
  .statusContainer {
    flex-direction: row;
    height: 200px;
  }

  .statusTimerContainer {
    width: 42px;
    flex-direction: column;
    margin-top: 9px;
  }

  .iconContainer {
    width: 18px;
    flex-direction: column;
  }

  .textContainer {
    width: 185px;
    flex-direction: column;
    margin-top: 0px;
  }

  .line {
    border-top: none;
    border-left: 2px solid #dddddd !important;
    width: 2px;
    height: 200px;
    left: 8px;
  }

  .lineActive {
    border-top: none;
    border-left: 2px solid transparent;
    width: 2px;
    height: 200px;
    left: 8px;
  }

  .lineTwo {
    border-top: none;
    border-left: 2px solid #dddddd !important;
    width: 2px;
    height: 165px;
    top: 109px;
    left: 8px;
  }
}
